import React from "react";

/* 
  Component for correcting inconsistencies in annotations
*/
const Corrector = () => {
  return <div>Hello, annotation corrector!</div>;
};

export default Corrector;
